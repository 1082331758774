import { CopyOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row, Tooltip } from 'antd';
import { BlockContentH1, BlockType } from 'pn-backend';
import { always, cond, equals, T } from 'ramda';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { useNotification, usePost } from '~hooks';
import { BlockContent, BlockProps } from '~types';
import { getText, isNews, isNewsFull } from '~utils';

import { FormBlock } from '../Wrappers';
import styles from './styles.module.scss';

const { TextArea } = Input;

const getBlockTitle = cond([
  [equals(BlockType.SeoTitle), always('SEO Title')],
  [equals(BlockType.Description), always('Description')],
  [T, always(null)],
]);

const getBlockPlaceholder = cond([
  [equals(BlockType.SeoTitle), always('Напишите Title')],
  [equals(BlockType.Description), always('Напишите Description')],
  [T, always(undefined)],
]);

const getBlockMaxCount = cond([
  [equals(BlockType.SeoTitle), always(120)],
  [equals(BlockType.Description), always(200)],
  [T, always(undefined)],
]);

export const SeoBlock: FC<BlockProps<BlockType.SeoTitle | BlockType.Description>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const { blocks, type: postType } = usePost();
  const [form] = Form.useForm();
  const notification = useNotification();

  const h1BlockContent: BlockContent | undefined = blocks.find(
    (block) => block.type === BlockType.H1,
  )?.content;

  const { text: h1Text } = h1BlockContent as BlockContentH1;

  const { content, type } = block;
  const { text } = content;

  const [count, setCount] = useState(getText(text).length);

  const onValuesChange = useCallback(({ text: changedText }: { text: string }) => {
    setCount(getText(changedText).length);
  }, []);

  const onCopyClick = () => {
    if (text !== h1Text) {
      form.setFieldValue('text', h1Text);
      setCount(getText(h1Text).length);
      form.submit();
    } else {
      notification({
        message: 'Значения блоков равны. Измените H1 и дождитесь сохранения.',
        placement: 'bottom',
        type: 'error',
      });
    }
  };

  const initialValues = useMemo(
    () => ({
      ...content,
    }),
    [content],
  );

  return (
    <FormBlock
      form={form}
      count={count}
      block={block}
      blockIndex={blockIndex}
      canDelete={canDelete}
      maxCount={getBlockMaxCount(type)}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
    >
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <Row className={styles.title} justify="space-between">
            <Col xs={12}>{getBlockTitle(type)} </Col>
            {(isNewsFull(postType) || isNews(postType)) && type === BlockType.SeoTitle && (
              <Col xs={12}>
                <Row justify="end">
                  <Tooltip title="Скопировать значение H1">
                    <CopyOutlined className={styles.copy} onClick={onCopyClick} />
                  </Tooltip>
                </Row>
              </Col>
            )}
          </Row>
        </Col>
        <Col span={24}>
          <Form.Item name="text" className={styles.inputRow}>
            <TextArea
              autoSize
              rows={1}
              className={styles.input}
              bordered={false}
              placeholder={getBlockPlaceholder(type)}
            />
          </Form.Item>
        </Col>
      </Row>
    </FormBlock>
  );
};
