import { CheckCircleOutlined, RedoOutlined } from '@ant-design/icons';
import { Col, Form, FormInstance, FormProps, Modal, Popover, Row, Spin, Typography } from 'antd';
import { differenceInMinutes, format, formatDistance, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { BlockType, UpdateBlockBodySecure } from 'pn-backend';
import {
  always,
  cond,
  dec,
  equals,
  inc,
  mergeRight,
  not,
  pick,
  pipe,
  prop,
  propEq,
  T,
} from 'ramda';
import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ZodIssue } from 'zod';

import { ValidationError } from '~components';
import { useDebounce } from '~hooks';
import { validationErrorsSelector } from '~selectors';
import { useDeleteBlockMutation, useUpdateBlockMutation } from '~services';
import { validationActions } from '~slices';
import { BlockContent, BlockProps } from '~types';
import { getBlockDocumentId, isDefined } from '~utils';

import { BlockWrapper } from '../BlockWrapper';

const { confirm } = Modal;

type GetMessage = (context: {
  isError: boolean;
  isLoading: boolean;
  lastModified: string;
  tryAgain(): void;
  validationError?: ZodIssue[] | Error;
}) => JSX.Element;

export interface FormBlockProps<T extends BlockType = BlockType> extends BlockProps<T> {
  bordered?: boolean;
  count?: number;
  form: FormInstance<BlockContent<T>>;
  formatValues?: (content: BlockContent<T>) => Partial<UpdateBlockBodySecure<T>>;
  initialValues?: BlockContent<T>;
  maxCount?: number;
  onValuesChange?(data: any): void;
}

const lastModifyFormat = 'dd.MM.yyyy HH:mm:ss';

const getMessage: GetMessage = cond([
  [pipe(prop('isLoading'), equals(true)), always(<Spin size="small" />)],
  [
    pipe(prop('validationError'), isDefined),
    ({ validationError }) => <ValidationError validationError={validationError} />,
  ],
  [
    pipe(prop('isError'), equals(true)),
    ({ lastModified, tryAgain }) => {
      const date = parseISO(lastModified);

      return (
        <Popover content={format(date, lastModifyFormat)}>
          <Typography.Text type="danger">
            Ошибка сохранения. Повторить <RedoOutlined onClick={tryAgain} />
          </Typography.Text>
        </Popover>
      );
    },
  ],
  [
    T,
    ({ lastModified }) => {
      const [now, setNow] = useState(new Date());
      const date = parseISO(lastModified);

      useEffect(() => {
        const interval = setInterval(() => {
          setNow(new Date());
        }, 1000);

        return () => {
          clearInterval(interval);
        };
      }, []);

      return (
        <Popover content={format(date, lastModifyFormat)}>
          <Typography.Text type="secondary">
            <CheckCircleOutlined />{' '}
            {`Сохранено ${
              differenceInMinutes(now, date) < 1
                ? 'только что'
                : formatDistance(date, now, {
                    addSuffix: true,
                    includeSeconds: true,
                    locale: ru,
                  })
            }`}
          </Typography.Text>
        </Popover>
      );
    },
  ],
]);

export const FormBlock: FC<PropsWithChildren<FormBlockProps>> = (props) => {
  const {
    block,
    bordered = false,
    canDelete = true,
    children,
    count,
    form,
    formatValues,
    initialValues,
    maxCount,
    onValuesChange,
  } = props;
  const dispatch = useDispatch();
  const validationErrors = useSelector(validationErrorsSelector);

  const [updateBlock, { isError, isLoading }] = useUpdateBlockMutation({});
  const [deleteBlock] = useDeleteBlockMutation({});
  const [validationError, setValidationError] = useState<ZodIssue[]>();

  const { content, id, sortOrder, updatedAt: lastModified } = block;
  const { getFieldsValue, setFields, submit } = form;

  const [previousFormValues, setPreviousFormValues] = useState(initialValues || {});
  const [isChanged, setIsChanged] = useState(false);

  const canSort = !isLoading && sortOrder >= 0;
  const canSortUp = !isLoading && sortOrder > 0;

  const handleDelete = useCallback(() => {
    confirm({
      onOk() {
        deleteBlock(id);
      },
      title: 'Вы действительно хотите удалить блок?',
    });
  }, [deleteBlock, id]);

  const handleBlur = useCallback(() => {
    if (isChanged) {
      submit();
      setIsChanged(false);
    }
  }, [isChanged, submit]);

  const handleSort = (direction: 'up' | 'down') => {
    updateBlock({
      id,
      sortOrder: direction === 'up' ? dec(sortOrder) : inc(sortOrder),
    });
  };

  const handleFinish = useCallback(async () => {
    const formValues = getFieldsValue();
    const formattedValues = formatValues?.(formValues) ?? { content: formValues };
    const blockBody = mergeRight({ content }, formattedValues);
    await updateBlock({
      ...blockBody,
      id,
    });
    dispatch(
      validationActions.setErrors({
        errors: validationErrors.filter(pipe(propEq('blockId', id), not)),
      }),
    );
  }, [getFieldsValue, formatValues, content, updateBlock, id, dispatch, validationErrors]);

  const handleFormChangeHandler = useDebounce(() => {
    submit();
    setIsChanged(false);
  }, 1000);

  const handleValuesChangeHandler: FormProps<BlockContent>['onValuesChange'] = useCallback(
    (changedValues: BlockContent) => {
      const formValuesToCompare = pick(Object.keys(changedValues))(previousFormValues);

      if (equals(changedValues, formValuesToCompare)) {
        return;
      }

      setFields(Object.keys(changedValues).map((name) => ({ errors: [], name })));

      setPreviousFormValues({ ...previousFormValues, ...changedValues });
      setIsChanged(true);
      // eslint-disable-next-line no-unused-expressions,@typescript-eslint/no-unused-expressions
      onValuesChange && onValuesChange(changedValues);
      handleFormChangeHandler.current();
    },
    [handleFormChangeHandler, onValuesChange, previousFormValues, setFields],
  );

  const handleKeyPress = useCallback((e: any) => {
    if (e.key === 'Enter') e.preventDefault();
  }, []);

  const Message = useCallback(
    () =>
      getMessage({
        isError,
        isLoading,
        lastModified,
        tryAgain: handleFinish,
        validationError,
      }),
    [isError, isLoading, lastModified, handleFinish, validationError],
  );

  useEffect(() => {
    if (isDefined(validationErrors)) {
      const blockValidationErrors = validationErrors.filter(propEq('blockId', id));
      if (blockValidationErrors.length > 0) {
        setValidationError(blockValidationErrors);
      } else {
        setValidationError(undefined);
      }
    } else {
      setValidationError(undefined);
    }
  }, [id, validationErrors]);

  return (
    <BlockWrapper
      id={getBlockDocumentId(id)}
      bordered={bordered}
      onDelete={handleDelete}
      maxCount={maxCount}
      count={count}
      canDelete={canDelete}
      message={<Message />}
      canSort={canSort}
      canSortUp={canSortUp}
      onSort={handleSort}
      error={isDefined(validationError) && validationError?.length > 0}
    >
      <Form
        name="block"
        form={form}
        onBlur={handleBlur}
        onFinish={handleFinish}
        initialValues={initialValues}
        onValuesChange={handleValuesChangeHandler}
        layout="vertical"
        onKeyPress={handleKeyPress}
        validateTrigger="onKeyPress"
      >
        <Row gutter={[18, 20]}>
          <Col span={24}>{children}</Col>
        </Row>
      </Form>
    </BlockWrapper>
  );
};
