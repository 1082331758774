import { Button, Col, MenuProps, Row, Space } from 'antd';
import classNames from 'classnames';
import { PostType } from 'pn-backend';
import { __, always, cond, equals, includes, isEmpty, not, omit, pipe, prop, T } from 'ramda';
import React, { ComponentProps, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { objectToSearchString, searchStringToObject } from 'serialize-query-params';

import { Filter } from '~assets';
import { Badge, Sort, SortField, TitlePage } from '~components';
import { BadgeColor, RoutesPath, SortDirections } from '~constants';
import { MobilePageHeader, PageHeader, PostsFilter, PostsList } from '~containers';
import { useIsMobile } from '~hooks';
import { countPostsSelector } from '~selectors';
import { useCreatePostMutation, useGetSectionsListQuery } from '~services';
import { isSuccessResult, sortToString } from '~utils';

import styles from './styles.module.scss';

interface Props {
  type?: PostType;
}

type FilterValue = ComponentProps<typeof PostsFilter>['values'];

const sortFields: SortField[] = [
  { label: 'По дате создания', value: 'createdAt' },
  { label: 'По дате публикации', value: 'publishedAt' },
];

const getCreateTitle = cond([
  [equals(PostType.NewsFull), always('Создать новость')],
  [T, always('Создать публикацию')],
]);

const getTitle = cond([
  [equals(PostType.NewsFull), always('Все новости')],
  [T, always('Все публикации')],
]);

const menuItems: MenuProps['items'] = [
  { key: PostType.Article, label: 'Статья' },
  { key: PostType.Longread, label: 'Лонгрид' },
  { key: PostType.NewsFull, label: 'Новость' },
  { key: PostType.Test, label: 'Тест' },
  { key: PostType.Card, label: 'Карточки' },
  { key: PostType.Spec, label: 'Спецпроект' },
];

export const PostsPage: React.FC<Props> = ({ type }) => {
  const isMountRef = useRef(true);
  const { search } = useLocation();
  const isMobile = useIsMobile();
  const initialQueryParams = searchStringToObject(search);
  const [viewFilter, setViewFilter] = useState(!isEmpty(initialQueryParams));
  const [, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<FilterValue>({ type, ...initialQueryParams });
  const [order, setOrder] = useState<Record<string, SortDirections>>({
    createdAt: SortDirections.Desc,
  });
  const [createPost] = useCreatePostMutation();
  const { data: sectionsResponse } = useGetSectionsListQuery({});
  const navigate = useNavigate();
  const sections = sectionsResponse?.results ?? [];

  const count = useSelector(countPostsSelector);

  const toggleViewFilter = () => {
    setViewFilter(not);
  };

  const getOnCreateSectionIds = (type: PostType): number[] => {
    switch (type) {
      case PostType.NewsFull:
        return sections.filter(pipe(prop('alias'), includes(__, ['news']))).map(prop<number>('id'));
      default:
        return [];
    }
  };

  const handleCreate = async ({ key }: any) => {
    const postType = type || key;
    const result = await createPost({
      isAffiliateArticle: false,
      isAuthorVisible: false,
      isModificationDateVisible: false,
      sectionIds: getOnCreateSectionIds(postType),
      type: postType,
    });

    if (isSuccessResult(result)) {
      navigate(generatePath(RoutesPath.PostEdit, { id: result?.data?.id }));
    }
  };

  const resetFilter = useCallback(() => {
    setFilter({});
  }, []);

  const handleChange = (values: FilterValue) => {
    setFilter((prevValues) => ({
      ...prevValues,
      ...values,
    }));
  };
  const handleSearch = (term: string) => {
    setFilter((prevValues) => ({ ...prevValues, term }));
  };

  const menuDropdown: MenuProps = {
    items: menuItems,
    onClick: handleCreate,
  };

  useEffect(() => {
    // При переходе через главное меню срабатывает этот эффект, но при первом запуске его нужно пропустить, чтобы не сбросились фильтры из querystring
    if (isMountRef.current) {
      isMountRef.current = false;
    } else {
      resetFilter();
    }
  }, [resetFilter, type]);

  useEffect(() => {
    const omitKeys = [];
    // Для новостей не нужно передавать тип в querystring
    if (type) {
      omitKeys.push('type');
    }
    setSearchParams(objectToSearchString(omit(omitKeys, filter)));

    // После сброса формы необходимо снова установить дефолтный фильтр
    if (isEmpty(filter)) {
      setFilter({ type });
    }
  }, [filter, setSearchParams, type]);

  return (
    <Row gutter={[0, 24]}>
      <Col span={24} className={styles.mobilePageHeader}>
        <MobilePageHeader
          title={getTitle(type)}
          titlePostfix={
            <Badge color={BadgeColor.BlueLight} size="small">
              {count ?? 0}
            </Badge>
          }
          placeholderText="Поиск"
          onCreate={handleCreate}
          onSearch={handleSearch}
          term={filter && filter.term}
          menuDropdown={type ? undefined : menuDropdown}
        />
      </Col>
      <Col span={24} className={styles.pageHeader}>
        <PageHeader
          placeholderText="Поиск"
          createButtonText={getCreateTitle(type)}
          onCreate={handleCreate}
          onSearch={handleSearch}
          term={filter && filter.term}
          menuDropdown={type ? undefined : menuDropdown}
        />
      </Col>
      <Col span={24}>
        <TitlePage
          title={getTitle(type)}
          titleVisible={!isMobile}
          titlePostfix={
            <Badge color={BadgeColor.BlueLight} size="large">
              {count ?? 0}
            </Badge>
          }
          rightContent={
            <Space>
              {viewFilter && (
                <Button size="large" type="text" onClick={resetFilter}>
                  Сбросить фильтры
                </Button>
              )}
              <Button
                size="large"
                icon={
                  <Filter
                    className={classNames(styles.filterIcon, viewFilter && styles.selected)}
                  />
                }
                onClick={toggleViewFilter}
                type={viewFilter ? 'primary' : 'default'}
              />
            </Space>
          }
        />
      </Col>
      {viewFilter && (
        <Col span={24}>
          <PostsFilter values={filter} onChange={handleChange} isNews={type === PostType.News} />
        </Col>
      )}
      <Col span={24}>
        <Sort fields={sortFields} value={order} onChange={setOrder} />
      </Col>
      <Col span={24}>
        <PostsList filter={filter} order={sortToString(order)} />
      </Col>
    </Row>
  );
};
