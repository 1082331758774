import classNames from 'classnames';
import { PostEditorSecure } from 'pn-backend';
import React from 'react';

import { Badge, EditorBadge } from '~components';

import styles from './styles.module.scss';

type Props = {
  editors: PostEditorSecure[];
  maxCount?: number;
  size?: 'small' | 'large';
};

export const Editors: React.FC<Props> = ({ editors = [], maxCount = 3, size = 'large' }) => {
  return (
    <div className={classNames(styles.editorsWrapper, styles[size])}>
      <span className={styles.title}>Сейчас редактируют</span>
      <div className={styles.badgesWrapper}>
        {editors.slice(0, maxCount).map((editor) => (
          <EditorBadge editor={editor.employee} />
        ))}
        {maxCount < editors.length && (
          <Badge className={styles.moreEditorsBadge}>+{editors.length - maxCount}</Badge>
        )}
      </div>
    </div>
  );
};
