import { ArrowLeftOutlined } from '@ant-design/icons';
import { Http } from '@status/codes';
import { Col, Row, Space, Spin, Typography } from 'antd';
import { AxiosError } from 'axios';
import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Button, Error } from '~components';
import { RoutesPath, SECOND } from '~constants';
import { Editors, PostBlocks, PostInfo, PostSettings } from '~containers';
import { useIsMobile } from '~hooks';
import { PostProvider } from '~providers';
import { blocksEntitySelector, hasPendingBlocksSelector } from '~selectors';
import { useGetPostBlocksQuery, useGetPostByIdQuery } from '~services';

import styles from './styles.module.scss';

export const PostEditPage = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const postId = parseInt(id, 10);
  const isMobile = useIsMobile();

  const {
    data: post,
    error: errorPost,
    isLoading: isPostLoading,
  } = useGetPostByIdQuery(
    { id: postId, relations: ['blocks', 'sections', 'tags', 'authors', 'feeds'] },
    // ToDo Поддержать SSE метод /:postId/updates
    { pollingInterval: 5 * SECOND, refetchOnMountOrArgChange: true, skip: !postId },
  );
  const { isLoading: isBlocksLoading } = useGetPostBlocksQuery(
    { postId, relations: ['blocks', 'files', 'blocks.files'] },
    { refetchOnMountOrArgChange: true, skip: !postId },
  );

  const blocks = useSelector(blocksEntitySelector.selectAll);

  const isSavingBlocks = useSelector(hasPendingBlocksSelector);

  const isLoading = isPostLoading || isBlocksLoading;

  const goBack = () => {
    navigate(-1);
  };

  // ToDo Сделать скелетоны
  if (isLoading) {
    return (
      <Row justify="center" align="middle" className={styles.loading}>
        <Col>
          <Space>
            <Spin size="large" />
            <Typography.Title level={3}>Загрузка публикации</Typography.Title>
          </Space>
        </Col>
      </Row>
    );
  }

  if (errorPost || !post) {
    const { status = Http.NotFound } = (errorPost as AxiosError) || {};

    return (
      <Row gutter={[0, 20]} className={styles.error}>
        <Col span={24}>
          <Button type="text" icon={<ArrowLeftOutlined />} onClick={goBack} disabled>
            Выйти
          </Button>
        </Col>
        <Col span={24}>
          <Error status={status} />
        </Col>
      </Row>
    );
  }

  const { createdAt, status, type, updatedAt } = post;

  const goToHistory = () => {
    navigate(generatePath(RoutesPath.Changes));
  };

  return (
    <PostProvider post={post}>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Button
                type="text"
                icon={<ArrowLeftOutlined />}
                onClick={goBack}
                disabled={isSavingBlocks}
              >
                Выйти
              </Button>
            </Col>
            <Col>
              <Button onClick={goToHistory} type="text">
                Журнал изменений
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <PostInfo
            type={type}
            status={status}
            createdAt={createdAt}
            updatedAt={updatedAt}
            edit={id !== 'new'}
          />
        </Col>
        <Col span={24}>
          <PostSettings />
        </Col>
        <Col span={24} className={styles.blocks}>
          <PostBlocks blocks={blocks} type={type} />
        </Col>
      </Row>
      {post.editors?.length > 0 && (
        <Row className={styles.editors}>
          <Col span={24}>
            <Editors
              maxCount={isMobile ? 3 : 5}
              editors={post.editors}
              size={isMobile ? 'small' : 'large'}
            />
          </Col>
        </Row>
      )}
    </PostProvider>
  );
};
