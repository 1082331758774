import { BlockType } from '../block/BlockType';
import { PostType } from './PostType';
import { PostTypeBlockMap } from './PostTypeBlockMap';

export const postTypeBlocksMap: {
  [T in PostType]: [
    PostTypeBlockMap[T][], // fixed default
    PostTypeBlockMap[T][], // default
    PostTypeBlockMap[T][], // additional
  ];
} = {
  [PostType.Article]: [
    [
      BlockType.Meta,
      BlockType.H1,
      BlockType.Subtitle,
      BlockType.SeoTitle,
      BlockType.Description,
      BlockType.Preview,
      BlockType.Cover,
      BlockType.Intro,
    ],
    [BlockType.Text],
    [
      BlockType.H2,
      BlockType.H3,
      BlockType.Text,
      BlockType.Quote,
      BlockType.MoreOnTopic,
      BlockType.Image,
      BlockType.Gallery,
      BlockType.Video,
      BlockType.Gif,
      BlockType.Widget,
      BlockType.Incut,
      BlockType.Quiz,
      BlockType.List,
    ],
  ],
  [PostType.Card]: [
    [
      BlockType.Meta,
      BlockType.H1,
      BlockType.Subtitle,
      BlockType.SeoTitle,
      BlockType.Description,
      BlockType.Preview,
      BlockType.Cover,
      BlockType.Intro,
    ],
    [],
    [BlockType.Card],
  ],
  [PostType.Custom]: [
    [BlockType.H1, BlockType.Subtitle],
    [BlockType.Text],
    [BlockType.Text],
  ],
  [PostType.Longread]: [
    [
      BlockType.Meta,
      BlockType.H1,
      BlockType.Subtitle,
      BlockType.SeoTitle,
      BlockType.Description,
      BlockType.Preview,
      BlockType.Cover,
      BlockType.Intro,
    ],
    [BlockType.Text],
    [
      BlockType.H2,
      BlockType.H3,
      BlockType.Text,
      BlockType.Image,
      BlockType.Quote,
      BlockType.MoreOnTopic,
      BlockType.Gallery,
      BlockType.Video,
      BlockType.Gif,
      BlockType.Widget,
      BlockType.Incut,
      BlockType.Quiz,
      BlockType.List,
      BlockType.Columns,
      BlockType.Line,
    ],
  ],
  [PostType.News]: [
    [BlockType.H1, BlockType.Text, BlockType.NewsSource],
    [],
    [],
  ],
  [PostType.NewsFull]: [
    [
      // BlockType.Meta,
      BlockType.H1,
      BlockType.NewsSource,
      BlockType.Subtitle,
      BlockType.SeoTitle,
      // BlockType.Description,
      BlockType.Preview,
      BlockType.Cover,
      // BlockType.Intro,
    ],
    [BlockType.Text],
    [
      BlockType.H2,
      BlockType.H3,
      BlockType.Text,
      BlockType.Quote,
      BlockType.MoreOnTopic,
      BlockType.Image,
      BlockType.Gallery,
      BlockType.Video,
      BlockType.Gif,
      BlockType.Widget,
      BlockType.Incut,
      BlockType.Quiz,
      BlockType.List,
    ],
  ],
  [PostType.Spec]: [
    [BlockType.H1, BlockType.Preview, BlockType.Cover, BlockType.SpecLink],
    [],
    [],
  ],
  [PostType.Test]: [
    [
      BlockType.Meta,
      BlockType.H1,
      BlockType.Subtitle,
      BlockType.Preview,
      BlockType.Cover,
      BlockType.Test,
      BlockType.TestResults,
    ],
    [],
    [],
  ],
};
