import { Avatar } from 'antd';
import { EmployeeShortSecure, getFileURL } from 'pn-backend';
import React, { useContext } from 'react';

import { ConfigContext } from '~providers';
import { getInitials } from '~utils';

import styles from './styles.module.scss';

type Props = {
  editor: EmployeeShortSecure;
};

export const EditorBadge: React.FC<Props> = ({ editor }) => {
  const { imageProxyHost } = useContext(ConfigContext);

  return (
    <div>
      <div className={styles.badgeWrapper}>
        <Avatar
          size={24}
          src={
            editor?.avatar
              ? getFileURL(editor?.avatar, {
                  domain: imageProxyHost,
                  height: 24,
                  width: 24,
                })
              : null
          }
        >
          <span className={styles.avatarInitials}>
            {getInitials(editor?.name?.split(' ') || [])}
          </span>
        </Avatar>
        {editor.name}
      </div>
    </div>
  );
};
